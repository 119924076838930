@import 'base/app';


.not-found{
    &__container{
        position: relative;
    }
    &__404{
        @include _992{
            position: absolute;
            top: 0;
            width: 100%;
            height: 100%;
        }
        text-align: center;
        svg{
            fill: #DADADA;
            opacity: 0.3;
            width: 100%;
            height: 600px;
            @include _992{
                height: unset;
            }
        }
    }
    &__content{
        position: absolute;
        top: 0;
        height: 100%;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        padding: 50px 0;
        @include _992{
            padding: 0;
            position: relative;
        }
        &-text{
            @include _992{
                padding: 0 0 30px;
            }
            span{
                display: block;
                max-width: 530px;
                font-size: 22px;
                line-height: 26px;
                @include _992{
                    font-size: 16px;
                    line-height: 22px;
                }
            }
        }
    }
}