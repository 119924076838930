.header{
    border-bottom: 1px solid #DADADA;
    &-container{
        display: grid;
        grid-template-columns: 145px 1fr;
    }
    &-logo{
        display: block;
        padding: 15px 0;
        img{
            width: 95px;
            height: 88px;
            object-fit: contain;
        }
    }
    &-main{
        display: flex;
        flex-direction: column;
    }

    &-top{
        justify-content: space-between;
        padding: 12px 0;
        border-bottom: 1px solid #DADADA;
    }
    &-city{
        font-weight: 400;
        font-size: 18px;
        line-height: 26px;
        color: $alt-clr;
    }
    &-leed{
      display: flex;
      align-items: center;
      gap: 18px;

      &-contacts {
        display: flex;
        align-items: center;
        gap: 8px;
        justify-content: center;
      }

        .btn {
          color: #ffffff;
          padding: 5px 10px;
        }

        a{
            //margin-left: 40px;
            font-size: 18px;
            line-height: 26px;
            //text-transform: uppercase;
            color: $alt-clr;
            transition: color $tr-time;
            &:hover{
                color: $main-clr-hover;
            }
        }
    }

    &-bottom{
        justify-content: space-between;
        flex: 1;
    }
    &-menu{
        $this: &;
        &__list{
            display: flex;
            flex-direction: row;
            align-items: center;
            height: 100%;
            @include _1200{
                display: block;
            }
        }
        &__listitem{
            height: 100%;
            margin-right: 55px;
            @include _1200{
                margin-right: 0;
                margin: 0 0 15px;
                height: unset;
            }
            > a{
	    	white-space: nowrap;
                height: 100%;
                @include _1200{
                    justify-content: flex-start;
                }
                &:hover{
                    span{
                        border-color: $main-clr-hover;
                    }
                }
                span{
                    font-size: 18px;
                    line-height: 24px;
                    color: $text-clr;
                    border-bottom: 1px solid transparent;
                    transition: border-color $tr-time;
                    @include _1200{
                        font-size: 14px;
                        line-height: 18px;
                    }
                }
            }
            &.has-child{
                @include _1200{
                    &.active{
                        > a::after{
                            transform: rotate(180deg);
                        }
                    }
                }
                > a::after{
                    content: '';
                    width: 0;
                    height: 0;
                    border-style: solid;
                    border-width: 6px 6px 0 6px;
                    border-color: $text-clr transparent transparent transparent;
                    position: relative;
                    margin-left: 14px;
                    @include _1200{
                        border-width: 4px 4px 0 4px;
                        margin-left: 10px;
                    }
                }
                &:hover{
                    #{$this}__drop{
                        opacity: 1;
                        visibility: visible;
                        transform: translate(0px, 0px);
                    }
                }
            }
        }
        &__drop{
            position: absolute;
            background: #ffffff;
            border: 1px solid #DADADA;
            padding: 25px 0;
            z-index: 99;
            opacity: 0;
            visibility: hidden;
            transform: translate(0px, -8px);
            // transition: $tr-time;
            @include _1200{
                position: static;
                visibility: visible;
                opacity: 1;
                padding: 0;
                border: none;
                padding-left: 20px;
                display: none;
                transform: none !important;
                &.active{
                    display: block;
                }
            }
        }
        &__dropitem{
            > a{
                display: block;
                padding: 10px 35px;
                font-size: 18px;
                line-height: 24px;
                &:hover{
                    color: $main-clr-hover;
                }
                @include _1200{
                    font-size: 14px;
                    line-height: 18px;
                    padding: 5px 0;
                }
            }
        }
    }
    &-links{
        align-items: center;
        .btn:nth-last-child(-n+2){ // предпоследний элемент
            padding-right: 0;
        }
        &__count{
            width: 30px;
            height: 30px;
            background-color: $main-clr;
            border-radius: 50%;
            font-size: 14px;
            line-height: 18px;
            color: #FCFCFC;
            transition: $tr-time;
            &:not(:last-child){
                margin-right: 20px;
            }
            &:last-child{
                margin-left: 20px;
            }
            &:empty{
                width: 0;
                height: 0;
                margin: 0;
            }
        }
    }
}


.hot-line{
    position: relative;
    margin-right: 15px;
    span{
        position: absolute;
        color: #c70000;
        top: -80%;
        font-size: 14px;
    }
}
