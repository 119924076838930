.swal2-popup.swal2-toast {
  box-sizing: border-box;
  grid-column: 1/4 !important;
  grid-row: 1/4 !important;
  grid-template-columns: 1fr 99fr 1fr;
  padding: 1em;
  overflow-y: hidden;
  background: #fff;
  box-shadow: 0 0 1px rgba(0, 0, 0, 0.075), 0 1px 2px rgba(0, 0, 0, 0.075), 1px 2px 4px rgba(0, 0, 0, 0.075), 1px 3px 8px rgba(0, 0, 0, 0.075), 2px 4px 16px rgba(0, 0, 0, 0.075);
  pointer-events: all; }

.swal2-popup.swal2-toast > * {
  grid-column: 2; }

.swal2-popup.swal2-toast .swal2-title {
  margin: .5em 1em;
  padding: 0;
  font-size: 1em;
  text-align: initial; }

.swal2-popup.swal2-toast .swal2-loading {
  justify-content: center; }

.swal2-popup.swal2-toast .swal2-input {
  height: 2em;
  margin: .5em;
  font-size: 1em; }

.swal2-popup.swal2-toast .swal2-validation-message {
  font-size: 1em; }

.swal2-popup.swal2-toast .swal2-footer {
  margin: .5em 0 0;
  padding: .5em 0 0;
  font-size: .8em; }

.swal2-popup.swal2-toast .swal2-close {
  grid-column: 3/3;
  grid-row: 1/99;
  align-self: center;
  width: .8em;
  height: .8em;
  margin: 0;
  font-size: 2em; }

.swal2-popup.swal2-toast .swal2-html-container {
  margin: .5em 1em;
  padding: 0;
  font-size: 1em;
  text-align: initial; }

.swal2-popup.swal2-toast .swal2-html-container:empty {
  padding: 0; }

.swal2-popup.swal2-toast .swal2-loader {
  grid-column: 1;
  grid-row: 1/99;
  align-self: center;
  width: 2em;
  height: 2em;
  margin: .25em; }

.swal2-popup.swal2-toast .swal2-icon {
  grid-column: 1;
  grid-row: 1/99;
  align-self: center;
  width: 2em;
  min-width: 2em;
  height: 2em;
  margin: 0 .5em 0 0; }

.swal2-popup.swal2-toast .swal2-icon .swal2-icon-content {
  display: flex;
  align-items: center;
  font-size: 1.8em;
  font-weight: 700; }

.swal2-popup.swal2-toast .swal2-icon.swal2-success .swal2-success-ring {
  width: 2em;
  height: 2em; }

.swal2-popup.swal2-toast .swal2-icon.swal2-error [class^=swal2-x-mark-line] {
  top: .875em;
  width: 1.375em; }

.swal2-popup.swal2-toast .swal2-icon.swal2-error [class^=swal2-x-mark-line][class$=left] {
  left: .3125em; }

.swal2-popup.swal2-toast .swal2-icon.swal2-error [class^=swal2-x-mark-line][class$=right] {
  right: .3125em; }

.swal2-popup.swal2-toast .swal2-actions {
  justify-content: flex-start;
  height: auto;
  margin: 0;
  margin-top: .5em;
  padding: 0 .5em; }

.swal2-popup.swal2-toast .swal2-styled {
  margin: .25em .5em;
  padding: .4em .6em;
  font-size: 1em; }

.swal2-popup.swal2-toast .swal2-success {
  border-color: #a5dc86; }

.swal2-popup.swal2-toast .swal2-success [class^=swal2-success-circular-line] {
  position: absolute;
  width: 1.6em;
  height: 3em;
  transform: rotate(45deg);
  border-radius: 50%; }

.swal2-popup.swal2-toast .swal2-success [class^=swal2-success-circular-line][class$=left] {
  top: -.8em;
  left: -.5em;
  transform: rotate(-45deg);
  transform-origin: 2em 2em;
  border-radius: 4em 0 0 4em; }

.swal2-popup.swal2-toast .swal2-success [class^=swal2-success-circular-line][class$=right] {
  top: -.25em;
  left: .9375em;
  transform-origin: 0 1.5em;
  border-radius: 0 4em 4em 0; }

.swal2-popup.swal2-toast .swal2-success .swal2-success-ring {
  width: 2em;
  height: 2em; }

.swal2-popup.swal2-toast .swal2-success .swal2-success-fix {
  top: 0;
  left: .4375em;
  width: .4375em;
  height: 2.6875em; }

.swal2-popup.swal2-toast .swal2-success [class^=swal2-success-line] {
  height: .3125em; }

.swal2-popup.swal2-toast .swal2-success [class^=swal2-success-line][class$=tip] {
  top: 1.125em;
  left: .1875em;
  width: .75em; }

.swal2-popup.swal2-toast .swal2-success [class^=swal2-success-line][class$=long] {
  top: .9375em;
  right: .1875em;
  width: 1.375em; }

.swal2-popup.swal2-toast .swal2-success.swal2-icon-show .swal2-success-line-tip {
  -webkit-animation: swal2-toast-animate-success-line-tip .75s;
  animation: swal2-toast-animate-success-line-tip .75s; }

.swal2-popup.swal2-toast .swal2-success.swal2-icon-show .swal2-success-line-long {
  -webkit-animation: swal2-toast-animate-success-line-long .75s;
  animation: swal2-toast-animate-success-line-long .75s; }

.swal2-popup.swal2-toast.swal2-show {
  -webkit-animation: swal2-toast-show .5s;
  animation: swal2-toast-show .5s; }

.swal2-popup.swal2-toast.swal2-hide {
  -webkit-animation: swal2-toast-hide .1s forwards;
  animation: swal2-toast-hide .1s forwards; }

.swal2-container {
  display: grid;
  position: fixed;
  z-index: 9999;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  box-sizing: border-box;
  grid-template-areas: "top-start     top            top-end" "center-start  center         center-end" "bottom-start  bottom-center  bottom-end";
  grid-template-rows: minmax(-webkit-min-content, auto) minmax(-webkit-min-content, auto) minmax(-webkit-min-content, auto);
  grid-template-rows: minmax(min-content, auto) minmax(min-content, auto) minmax(min-content, auto);
  height: 100%;
  padding: .625em;
  overflow-x: hidden;
  transition: background-color .1s;
  -webkit-overflow-scrolling: touch; }

.swal2-container.swal2-backdrop-show, .swal2-container.swal2-noanimation {
  background: rgba(0, 0, 0, 0.4); }

.swal2-container.swal2-backdrop-hide {
  background: 0 0 !important; }

.swal2-container.swal2-bottom-start, .swal2-container.swal2-center-start, .swal2-container.swal2-top-start {
  grid-template-columns: minmax(0, 1fr) auto auto; }

.swal2-container.swal2-bottom, .swal2-container.swal2-center, .swal2-container.swal2-top {
  grid-template-columns: auto minmax(0, 1fr) auto; }

.swal2-container.swal2-bottom-end, .swal2-container.swal2-center-end, .swal2-container.swal2-top-end {
  grid-template-columns: auto auto minmax(0, 1fr); }

.swal2-container.swal2-top-start > .swal2-popup {
  align-self: start; }

.swal2-container.swal2-top > .swal2-popup {
  grid-column: 2;
  align-self: start;
  justify-self: center; }

.swal2-container.swal2-top-end > .swal2-popup, .swal2-container.swal2-top-right > .swal2-popup {
  grid-column: 3;
  align-self: start;
  justify-self: end; }

.swal2-container.swal2-center-left > .swal2-popup, .swal2-container.swal2-center-start > .swal2-popup {
  grid-row: 2;
  align-self: center; }

.swal2-container.swal2-center > .swal2-popup {
  grid-column: 2;
  grid-row: 2;
  align-self: center;
  justify-self: center; }

.swal2-container.swal2-center-end > .swal2-popup, .swal2-container.swal2-center-right > .swal2-popup {
  grid-column: 3;
  grid-row: 2;
  align-self: center;
  justify-self: end; }

.swal2-container.swal2-bottom-left > .swal2-popup, .swal2-container.swal2-bottom-start > .swal2-popup {
  grid-column: 1;
  grid-row: 3;
  align-self: end; }

.swal2-container.swal2-bottom > .swal2-popup {
  grid-column: 2;
  grid-row: 3;
  justify-self: center;
  align-self: end; }

.swal2-container.swal2-bottom-end > .swal2-popup, .swal2-container.swal2-bottom-right > .swal2-popup {
  grid-column: 3;
  grid-row: 3;
  align-self: end;
  justify-self: end; }

.swal2-container.swal2-grow-fullscreen > .swal2-popup, .swal2-container.swal2-grow-row > .swal2-popup {
  grid-column: 1/4;
  width: 100%; }

.swal2-container.swal2-grow-column > .swal2-popup, .swal2-container.swal2-grow-fullscreen > .swal2-popup {
  grid-row: 1/4;
  align-self: stretch; }

.swal2-container.swal2-no-transition {
  transition: none !important; }

.swal2-popup {
  display: none;
  position: relative;
  box-sizing: border-box;
  grid-template-columns: minmax(0, 100%);
  width: 32em;
  max-width: 100%;
  padding: 0 0 1.25em;
  border: none;
  border-radius: 5px;
  background: #fff;
  color: #545454;
  font-family: inherit;
  font-size: 1rem; }

.swal2-popup:focus {
  outline: 0; }

.swal2-popup.swal2-loading {
  overflow-y: hidden; }

.swal2-title {
  position: relative;
  max-width: 100%;
  margin: 0;
  padding: .8em 1em 0;
  color: #595959;
  font-size: 1.875em;
  font-weight: 600;
  text-align: center;
  text-transform: none;
  word-wrap: break-word; }

.swal2-actions {
  display: flex;
  z-index: 1;
  box-sizing: border-box;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
  width: auto;
  margin: 1.25em auto 0;
  padding: 0; }

.swal2-actions:not(.swal2-loading) .swal2-styled[disabled] {
  opacity: .4; }

.swal2-actions:not(.swal2-loading) .swal2-styled:hover {
  background-image: linear-gradient(rgba(0, 0, 0, 0.1), rgba(0, 0, 0, 0.1)); }

.swal2-actions:not(.swal2-loading) .swal2-styled:active {
  background-image: linear-gradient(rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0.2)); }

.swal2-loader {
  display: none;
  align-items: center;
  justify-content: center;
  width: 2.2em;
  height: 2.2em;
  margin: 0 1.875em;
  -webkit-animation: swal2-rotate-loading 1.5s linear 0s infinite normal;
  animation: swal2-rotate-loading 1.5s linear 0s infinite normal;
  border-width: .25em;
  border-style: solid;
  border-radius: 100%;
  border-color: #2778c4 transparent #2778c4 transparent; }

.swal2-styled {
  margin: .3125em;
  padding: .625em 1.1em;
  transition: box-shadow .1s;
  box-shadow: 0 0 0 3px transparent;
  font-weight: 500; }

.swal2-styled:not([disabled]) {
  cursor: pointer; }

.swal2-styled.swal2-confirm {
  border: 0;
  border-radius: .25em;
  background: initial;
  background-color: #7066e0;
  color: #fff;
  font-size: 1em; }

.swal2-styled.swal2-confirm:focus {
  box-shadow: 0 0 0 3px rgba(112, 102, 224, 0.5); }

.swal2-styled.swal2-deny {
  border: 0;
  border-radius: .25em;
  background: initial;
  background-color: #dc3741;
  color: #fff;
  font-size: 1em; }

.swal2-styled.swal2-deny:focus {
  box-shadow: 0 0 0 3px rgba(220, 55, 65, 0.5); }

.swal2-styled.swal2-cancel {
  border: 0;
  border-radius: .25em;
  background: initial;
  background-color: #6e7881;
  color: #fff;
  font-size: 1em; }

.swal2-styled.swal2-cancel:focus {
  box-shadow: 0 0 0 3px rgba(110, 120, 129, 0.5); }

.swal2-styled.swal2-default-outline:focus {
  box-shadow: 0 0 0 3px rgba(100, 150, 200, 0.5); }

.swal2-styled:focus {
  outline: 0; }

.swal2-styled::-moz-focus-inner {
  border: 0; }

.swal2-footer {
  justify-content: center;
  margin: 1em 0 0;
  padding: 1em 1em 0;
  border-top: 1px solid #eee;
  color: #545454;
  font-size: 1em; }

.swal2-timer-progress-bar-container {
  position: absolute;
  right: 0;
  bottom: 0;
  left: 0;
  grid-column: auto !important;
  height: .25em;
  overflow: hidden;
  border-bottom-right-radius: 5px;
  border-bottom-left-radius: 5px; }

.swal2-timer-progress-bar {
  width: 100%;
  height: .25em;
  background: rgba(0, 0, 0, 0.2); }

.swal2-image {
  max-width: 100%;
  margin: 2em auto 1em; }

.swal2-close {
  z-index: 2;
  align-items: center;
  justify-content: center;
  width: 1.2em;
  height: 1.2em;
  margin-top: 0;
  margin-right: 0;
  margin-bottom: -1.2em;
  padding: 0;
  overflow: hidden;
  transition: color .1s,box-shadow .1s;
  border: none;
  border-radius: 5px;
  background: 0 0;
  color: #ccc;
  font-family: serif;
  font-family: monospace;
  font-size: 2.5em;
  cursor: pointer;
  justify-self: end; }

.swal2-close:hover {
  transform: none;
  background: 0 0;
  color: #f27474; }

.swal2-close:focus {
  outline: 0;
  box-shadow: inset 0 0 0 3px rgba(100, 150, 200, 0.5); }

.swal2-close::-moz-focus-inner {
  border: 0; }

.swal2-html-container {
  z-index: 1;
  justify-content: center;
  margin: 1em 1.6em .3em;
  padding: 0;
  overflow: auto;
  color: #545454;
  font-size: 1.125em;
  font-weight: 400;
  line-height: normal;
  text-align: center;
  word-wrap: break-word;
  word-break: break-word; }

.swal2-checkbox, .swal2-file, .swal2-input, .swal2-radio, .swal2-select, .swal2-textarea {
  margin: 1em 2em 0; }

.swal2-file, .swal2-input, .swal2-textarea {
  box-sizing: border-box;
  width: auto;
  transition: border-color .1s,box-shadow .1s;
  border: 1px solid #d9d9d9;
  border-radius: .1875em;
  background: inherit;
  box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.06), 0 0 0 3px transparent;
  color: inherit;
  font-size: 1.125em; }

.swal2-file.swal2-inputerror, .swal2-input.swal2-inputerror, .swal2-textarea.swal2-inputerror {
  border-color: #f27474 !important;
  box-shadow: 0 0 2px #f27474 !important; }

.swal2-file:focus, .swal2-input:focus, .swal2-textarea:focus {
  border: 1px solid #b4dbed;
  outline: 0;
  box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.06), 0 0 0 3px rgba(100, 150, 200, 0.5); }

.swal2-file::-moz-placeholder, .swal2-input::-moz-placeholder, .swal2-textarea::-moz-placeholder {
  color: #ccc; }

.swal2-file:-ms-input-placeholder, .swal2-input:-ms-input-placeholder, .swal2-textarea:-ms-input-placeholder {
  color: #ccc; }

.swal2-file::placeholder, .swal2-input::placeholder, .swal2-textarea::placeholder {
  color: #ccc; }

.swal2-range {
  margin: 1em 2em 0;
  background: #fff; }

.swal2-range input {
  width: 80%; }

.swal2-range output {
  width: 20%;
  color: inherit;
  font-weight: 600;
  text-align: center; }

.swal2-range input, .swal2-range output {
  height: 2.625em;
  padding: 0;
  font-size: 1.125em;
  line-height: 2.625em; }

.swal2-input {
  height: 2.625em;
  padding: 0 .75em; }

.swal2-file {
  width: 75%;
  margin-right: auto;
  margin-left: auto;
  background: inherit;
  font-size: 1.125em; }

.swal2-textarea {
  height: 6.75em;
  padding: .75em; }

.swal2-select {
  min-width: 50%;
  max-width: 100%;
  padding: .375em .625em;
  background: inherit;
  color: inherit;
  font-size: 1.125em; }

.swal2-checkbox, .swal2-radio {
  align-items: center;
  justify-content: center;
  background: #fff;
  color: inherit; }

.swal2-checkbox label, .swal2-radio label {
  margin: 0 .6em;
  font-size: 1.125em; }

.swal2-checkbox input, .swal2-radio input {
  flex-shrink: 0;
  margin: 0 .4em; }

.swal2-input-label {
  display: flex;
  justify-content: center;
  margin: 1em auto 0; }

.swal2-validation-message {
  align-items: center;
  justify-content: center;
  margin: 1em 0 0;
  padding: .625em;
  overflow: hidden;
  background: #f0f0f0;
  color: #666;
  font-size: 1em;
  font-weight: 300; }

.swal2-validation-message::before {
  content: "!";
  display: inline-block;
  width: 1.5em;
  min-width: 1.5em;
  height: 1.5em;
  margin: 0 .625em;
  border-radius: 50%;
  background-color: #f27474;
  color: #fff;
  font-weight: 600;
  line-height: 1.5em;
  text-align: center; }

.swal2-icon {
  position: relative;
  box-sizing: content-box;
  justify-content: center;
  width: 5em;
  height: 5em;
  margin: 2.5em auto .6em;
  border: .25em solid transparent;
  border-radius: 50%;
  border-color: #000;
  font-family: inherit;
  line-height: 5em;
  cursor: default;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none; }

.swal2-icon .swal2-icon-content {
  display: flex;
  align-items: center;
  font-size: 3.75em; }

.swal2-icon.swal2-error {
  border-color: #f27474;
  color: #f27474; }

.swal2-icon.swal2-error .swal2-x-mark {
  position: relative;
  flex-grow: 1; }

.swal2-icon.swal2-error [class^=swal2-x-mark-line] {
  display: block;
  position: absolute;
  top: 2.3125em;
  width: 2.9375em;
  height: .3125em;
  border-radius: .125em;
  background-color: #f27474; }

.swal2-icon.swal2-error [class^=swal2-x-mark-line][class$=left] {
  left: 1.0625em;
  transform: rotate(45deg); }

.swal2-icon.swal2-error [class^=swal2-x-mark-line][class$=right] {
  right: 1em;
  transform: rotate(-45deg); }

.swal2-icon.swal2-error.swal2-icon-show {
  -webkit-animation: swal2-animate-error-icon .5s;
  animation: swal2-animate-error-icon .5s; }

.swal2-icon.swal2-error.swal2-icon-show .swal2-x-mark {
  -webkit-animation: swal2-animate-error-x-mark .5s;
  animation: swal2-animate-error-x-mark .5s; }

.swal2-icon.swal2-warning {
  border-color: #facea8;
  color: #f8bb86; }

.swal2-icon.swal2-warning.swal2-icon-show {
  -webkit-animation: swal2-animate-error-icon .5s;
  animation: swal2-animate-error-icon .5s; }

.swal2-icon.swal2-warning.swal2-icon-show .swal2-icon-content {
  -webkit-animation: swal2-animate-i-mark .5s;
  animation: swal2-animate-i-mark .5s; }

.swal2-icon.swal2-info {
  border-color: #9de0f6;
  color: #3fc3ee; }

.swal2-icon.swal2-info.swal2-icon-show {
  -webkit-animation: swal2-animate-error-icon .5s;
  animation: swal2-animate-error-icon .5s; }

.swal2-icon.swal2-info.swal2-icon-show .swal2-icon-content {
  -webkit-animation: swal2-animate-i-mark .8s;
  animation: swal2-animate-i-mark .8s; }

.swal2-icon.swal2-question {
  border-color: #c9dae1;
  color: #87adbd; }

.swal2-icon.swal2-question.swal2-icon-show {
  -webkit-animation: swal2-animate-error-icon .5s;
  animation: swal2-animate-error-icon .5s; }

.swal2-icon.swal2-question.swal2-icon-show .swal2-icon-content {
  -webkit-animation: swal2-animate-question-mark .8s;
  animation: swal2-animate-question-mark .8s; }

.swal2-icon.swal2-success {
  border-color: #a5dc86;
  color: #a5dc86; }

.swal2-icon.swal2-success [class^=swal2-success-circular-line] {
  position: absolute;
  width: 3.75em;
  height: 7.5em;
  transform: rotate(45deg);
  border-radius: 50%; }

.swal2-icon.swal2-success [class^=swal2-success-circular-line][class$=left] {
  top: -.4375em;
  left: -2.0635em;
  transform: rotate(-45deg);
  transform-origin: 3.75em 3.75em;
  border-radius: 7.5em 0 0 7.5em; }

.swal2-icon.swal2-success [class^=swal2-success-circular-line][class$=right] {
  top: -.6875em;
  left: 1.875em;
  transform: rotate(-45deg);
  transform-origin: 0 3.75em;
  border-radius: 0 7.5em 7.5em 0; }

.swal2-icon.swal2-success .swal2-success-ring {
  position: absolute;
  z-index: 2;
  top: -.25em;
  left: -.25em;
  box-sizing: content-box;
  width: 100%;
  height: 100%;
  border: 0.25em solid rgba(165, 220, 134, 0.3);
  border-radius: 50%; }

.swal2-icon.swal2-success .swal2-success-fix {
  position: absolute;
  z-index: 1;
  top: .5em;
  left: 1.625em;
  width: .4375em;
  height: 5.625em;
  transform: rotate(-45deg); }

.swal2-icon.swal2-success [class^=swal2-success-line] {
  display: block;
  position: absolute;
  z-index: 2;
  height: .3125em;
  border-radius: .125em;
  background-color: #a5dc86; }

.swal2-icon.swal2-success [class^=swal2-success-line][class$=tip] {
  top: 2.875em;
  left: .8125em;
  width: 1.5625em;
  transform: rotate(45deg); }

.swal2-icon.swal2-success [class^=swal2-success-line][class$=long] {
  top: 2.375em;
  right: .5em;
  width: 2.9375em;
  transform: rotate(-45deg); }

.swal2-icon.swal2-success.swal2-icon-show .swal2-success-line-tip {
  -webkit-animation: swal2-animate-success-line-tip .75s;
  animation: swal2-animate-success-line-tip .75s; }

.swal2-icon.swal2-success.swal2-icon-show .swal2-success-line-long {
  -webkit-animation: swal2-animate-success-line-long .75s;
  animation: swal2-animate-success-line-long .75s; }

.swal2-icon.swal2-success.swal2-icon-show .swal2-success-circular-line-right {
  -webkit-animation: swal2-rotate-success-circular-line 4.25s ease-in;
  animation: swal2-rotate-success-circular-line 4.25s ease-in; }

.swal2-progress-steps {
  flex-wrap: wrap;
  align-items: center;
  max-width: 100%;
  margin: 1.25em auto;
  padding: 0;
  background: inherit;
  font-weight: 600; }

.swal2-progress-steps li {
  display: inline-block;
  position: relative; }

.swal2-progress-steps .swal2-progress-step {
  z-index: 20;
  flex-shrink: 0;
  width: 2em;
  height: 2em;
  border-radius: 2em;
  background: #2778c4;
  color: #fff;
  line-height: 2em;
  text-align: center; }

.swal2-progress-steps .swal2-progress-step.swal2-active-progress-step {
  background: #2778c4; }

.swal2-progress-steps .swal2-progress-step.swal2-active-progress-step ~ .swal2-progress-step {
  background: #add8e6;
  color: #fff; }

.swal2-progress-steps .swal2-progress-step.swal2-active-progress-step ~ .swal2-progress-step-line {
  background: #add8e6; }

.swal2-progress-steps .swal2-progress-step-line {
  z-index: 10;
  flex-shrink: 0;
  width: 2.5em;
  height: .4em;
  margin: 0 -1px;
  background: #2778c4; }

[class^=swal2] {
  -webkit-tap-highlight-color: transparent; }

.swal2-show {
  -webkit-animation: swal2-show .3s;
  animation: swal2-show .3s; }

.swal2-hide {
  -webkit-animation: swal2-hide .15s forwards;
  animation: swal2-hide .15s forwards; }

.swal2-noanimation {
  transition: none; }

.swal2-scrollbar-measure {
  position: absolute;
  top: -9999px;
  width: 50px;
  height: 50px;
  overflow: scroll; }

.swal2-rtl .swal2-close {
  margin-right: initial;
  margin-left: 0; }

.swal2-rtl .swal2-timer-progress-bar {
  right: 0;
  left: auto; }

@-webkit-keyframes swal2-toast-show {
  0% {
    transform: translateY(-0.625em) rotateZ(2deg); }
  33% {
    transform: translateY(0) rotateZ(-2deg); }
  66% {
    transform: translateY(0.3125em) rotateZ(2deg); }
  100% {
    transform: translateY(0) rotateZ(0); } }

@keyframes swal2-toast-show {
  0% {
    transform: translateY(-0.625em) rotateZ(2deg); }
  33% {
    transform: translateY(0) rotateZ(-2deg); }
  66% {
    transform: translateY(0.3125em) rotateZ(2deg); }
  100% {
    transform: translateY(0) rotateZ(0); } }

@-webkit-keyframes swal2-toast-hide {
  100% {
    transform: rotateZ(1deg);
    opacity: 0; } }

@keyframes swal2-toast-hide {
  100% {
    transform: rotateZ(1deg);
    opacity: 0; } }

@-webkit-keyframes swal2-toast-animate-success-line-tip {
  0% {
    top: .5625em;
    left: .0625em;
    width: 0; }
  54% {
    top: .125em;
    left: .125em;
    width: 0; }
  70% {
    top: .625em;
    left: -.25em;
    width: 1.625em; }
  84% {
    top: 1.0625em;
    left: .75em;
    width: .5em; }
  100% {
    top: 1.125em;
    left: .1875em;
    width: .75em; } }

@keyframes swal2-toast-animate-success-line-tip {
  0% {
    top: .5625em;
    left: .0625em;
    width: 0; }
  54% {
    top: .125em;
    left: .125em;
    width: 0; }
  70% {
    top: .625em;
    left: -.25em;
    width: 1.625em; }
  84% {
    top: 1.0625em;
    left: .75em;
    width: .5em; }
  100% {
    top: 1.125em;
    left: .1875em;
    width: .75em; } }

@-webkit-keyframes swal2-toast-animate-success-line-long {
  0% {
    top: 1.625em;
    right: 1.375em;
    width: 0; }
  65% {
    top: 1.25em;
    right: .9375em;
    width: 0; }
  84% {
    top: .9375em;
    right: 0;
    width: 1.125em; }
  100% {
    top: .9375em;
    right: .1875em;
    width: 1.375em; } }

@keyframes swal2-toast-animate-success-line-long {
  0% {
    top: 1.625em;
    right: 1.375em;
    width: 0; }
  65% {
    top: 1.25em;
    right: .9375em;
    width: 0; }
  84% {
    top: .9375em;
    right: 0;
    width: 1.125em; }
  100% {
    top: .9375em;
    right: .1875em;
    width: 1.375em; } }

@-webkit-keyframes swal2-show {
  0% {
    transform: scale(0.7); }
  45% {
    transform: scale(1.05); }
  80% {
    transform: scale(0.95); }
  100% {
    transform: scale(1); } }

@keyframes swal2-show {
  0% {
    transform: scale(0.7); }
  45% {
    transform: scale(1.05); }
  80% {
    transform: scale(0.95); }
  100% {
    transform: scale(1); } }

@-webkit-keyframes swal2-hide {
  0% {
    transform: scale(1);
    opacity: 1; }
  100% {
    transform: scale(0.5);
    opacity: 0; } }

@keyframes swal2-hide {
  0% {
    transform: scale(1);
    opacity: 1; }
  100% {
    transform: scale(0.5);
    opacity: 0; } }

@-webkit-keyframes swal2-animate-success-line-tip {
  0% {
    top: 1.1875em;
    left: .0625em;
    width: 0; }
  54% {
    top: 1.0625em;
    left: .125em;
    width: 0; }
  70% {
    top: 2.1875em;
    left: -.375em;
    width: 3.125em; }
  84% {
    top: 3em;
    left: 1.3125em;
    width: 1.0625em; }
  100% {
    top: 2.8125em;
    left: .8125em;
    width: 1.5625em; } }

@keyframes swal2-animate-success-line-tip {
  0% {
    top: 1.1875em;
    left: .0625em;
    width: 0; }
  54% {
    top: 1.0625em;
    left: .125em;
    width: 0; }
  70% {
    top: 2.1875em;
    left: -.375em;
    width: 3.125em; }
  84% {
    top: 3em;
    left: 1.3125em;
    width: 1.0625em; }
  100% {
    top: 2.8125em;
    left: .8125em;
    width: 1.5625em; } }

@-webkit-keyframes swal2-animate-success-line-long {
  0% {
    top: 3.375em;
    right: 2.875em;
    width: 0; }
  65% {
    top: 3.375em;
    right: 2.875em;
    width: 0; }
  84% {
    top: 2.1875em;
    right: 0;
    width: 3.4375em; }
  100% {
    top: 2.375em;
    right: .5em;
    width: 2.9375em; } }

@keyframes swal2-animate-success-line-long {
  0% {
    top: 3.375em;
    right: 2.875em;
    width: 0; }
  65% {
    top: 3.375em;
    right: 2.875em;
    width: 0; }
  84% {
    top: 2.1875em;
    right: 0;
    width: 3.4375em; }
  100% {
    top: 2.375em;
    right: .5em;
    width: 2.9375em; } }

@-webkit-keyframes swal2-rotate-success-circular-line {
  0% {
    transform: rotate(-45deg); }
  5% {
    transform: rotate(-45deg); }
  12% {
    transform: rotate(-405deg); }
  100% {
    transform: rotate(-405deg); } }

@keyframes swal2-rotate-success-circular-line {
  0% {
    transform: rotate(-45deg); }
  5% {
    transform: rotate(-45deg); }
  12% {
    transform: rotate(-405deg); }
  100% {
    transform: rotate(-405deg); } }

@-webkit-keyframes swal2-animate-error-x-mark {
  0% {
    margin-top: 1.625em;
    transform: scale(0.4);
    opacity: 0; }
  50% {
    margin-top: 1.625em;
    transform: scale(0.4);
    opacity: 0; }
  80% {
    margin-top: -.375em;
    transform: scale(1.15); }
  100% {
    margin-top: 0;
    transform: scale(1);
    opacity: 1; } }

@keyframes swal2-animate-error-x-mark {
  0% {
    margin-top: 1.625em;
    transform: scale(0.4);
    opacity: 0; }
  50% {
    margin-top: 1.625em;
    transform: scale(0.4);
    opacity: 0; }
  80% {
    margin-top: -.375em;
    transform: scale(1.15); }
  100% {
    margin-top: 0;
    transform: scale(1);
    opacity: 1; } }

@-webkit-keyframes swal2-animate-error-icon {
  0% {
    transform: rotateX(100deg);
    opacity: 0; }
  100% {
    transform: rotateX(0);
    opacity: 1; } }

@keyframes swal2-animate-error-icon {
  0% {
    transform: rotateX(100deg);
    opacity: 0; }
  100% {
    transform: rotateX(0);
    opacity: 1; } }

@-webkit-keyframes swal2-rotate-loading {
  0% {
    transform: rotate(0); }
  100% {
    transform: rotate(360deg); } }

@keyframes swal2-rotate-loading {
  0% {
    transform: rotate(0); }
  100% {
    transform: rotate(360deg); } }

@-webkit-keyframes swal2-animate-question-mark {
  0% {
    transform: rotateY(-360deg); }
  100% {
    transform: rotateY(0); } }

@keyframes swal2-animate-question-mark {
  0% {
    transform: rotateY(-360deg); }
  100% {
    transform: rotateY(0); } }

@-webkit-keyframes swal2-animate-i-mark {
  0% {
    transform: rotateZ(45deg);
    opacity: 0; }
  25% {
    transform: rotateZ(-25deg);
    opacity: .4; }
  50% {
    transform: rotateZ(15deg);
    opacity: .8; }
  75% {
    transform: rotateZ(-5deg);
    opacity: 1; }
  100% {
    transform: rotateX(0);
    opacity: 1; } }

@keyframes swal2-animate-i-mark {
  0% {
    transform: rotateZ(45deg);
    opacity: 0; }
  25% {
    transform: rotateZ(-25deg);
    opacity: .4; }
  50% {
    transform: rotateZ(15deg);
    opacity: .8; }
  75% {
    transform: rotateZ(-5deg);
    opacity: 1; }
  100% {
    transform: rotateX(0);
    opacity: 1; } }

body.swal2-shown:not(.swal2-no-backdrop):not(.swal2-toast-shown) {
  overflow: hidden; }

body.swal2-height-auto {
  height: auto !important; }

body.swal2-no-backdrop .swal2-container {
  background-color: transparent !important;
  pointer-events: none; }

body.swal2-no-backdrop .swal2-container .swal2-popup {
  pointer-events: all; }

body.swal2-no-backdrop .swal2-container .swal2-modal {
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.4); }

@media print {
  body.swal2-shown:not(.swal2-no-backdrop):not(.swal2-toast-shown) {
    overflow-y: scroll !important; }
  body.swal2-shown:not(.swal2-no-backdrop):not(.swal2-toast-shown) > [aria-hidden=true] {
    display: none; }
  body.swal2-shown:not(.swal2-no-backdrop):not(.swal2-toast-shown) .swal2-container {
    position: static !important; } }

body.swal2-toast-shown .swal2-container {
  box-sizing: border-box;
  width: 360px;
  max-width: 100%;
  background-color: transparent;
  pointer-events: none; }

body.swal2-toast-shown .swal2-container.swal2-top {
  top: 0;
  right: auto;
  bottom: auto;
  left: 50%;
  transform: translateX(-50%); }

body.swal2-toast-shown .swal2-container.swal2-top-end, body.swal2-toast-shown .swal2-container.swal2-top-right {
  top: 0;
  right: 0;
  bottom: auto;
  left: auto; }

body.swal2-toast-shown .swal2-container.swal2-top-left, body.swal2-toast-shown .swal2-container.swal2-top-start {
  top: 0;
  right: auto;
  bottom: auto;
  left: 0; }

body.swal2-toast-shown .swal2-container.swal2-center-left, body.swal2-toast-shown .swal2-container.swal2-center-start {
  top: 50%;
  right: auto;
  bottom: auto;
  left: 0;
  transform: translateY(-50%); }

body.swal2-toast-shown .swal2-container.swal2-center {
  top: 50%;
  right: auto;
  bottom: auto;
  left: 50%;
  transform: translate(-50%, -50%); }

body.swal2-toast-shown .swal2-container.swal2-center-end, body.swal2-toast-shown .swal2-container.swal2-center-right {
  top: 50%;
  right: 0;
  bottom: auto;
  left: auto;
  transform: translateY(-50%); }

body.swal2-toast-shown .swal2-container.swal2-bottom-left, body.swal2-toast-shown .swal2-container.swal2-bottom-start {
  top: auto;
  right: auto;
  bottom: 0;
  left: 0; }

body.swal2-toast-shown .swal2-container.swal2-bottom {
  top: auto;
  right: auto;
  bottom: 0;
  left: 50%;
  transform: translateX(-50%); }

body.swal2-toast-shown .swal2-container.swal2-bottom-end, body.swal2-toast-shown .swal2-container.swal2-bottom-right {
  top: auto;
  right: 0;
  bottom: 0;
  left: auto; }

@font-face {
  font-family: 'Nunito Sans';
  src: url("../fonts/NunitoSans-Regular.ttf");
  src: local("Nunito Sans Regular"), local("NunitoSans-Regular"), url("../fonts/NunitoSans-Regular.ttf") format("truetype");
  font-weight: 400;
  font-style: normal; }

@font-face {
  font-family: 'Nunito Sans';
  src: url("../fonts/NunitoSans-Light.ttf");
  src: local("Nunito Sans Light"), local("NunitoSans-Light"), url("../fonts/NunitoSans-Light.ttf") format("truetype");
  font-weight: 300;
  font-style: normal; }

@font-face {
  font-family: 'Nunito Sans';
  src: url("../fonts/NunitoSans-LightItalic.eot");
  src: local("Nunito Sans Light Italic"), local("NunitoSans-LightItalic"), url("../fonts/NunitoSans-LightItalic.eot?#iefix") format("embedded-opentype"), url("../fonts/NunitoSans-LightItalic.woff") format("woff"), url("../fonts/NunitoSans-LightItalic.ttf") format("truetype");
  font-weight: 300;
  font-style: italic; }

@font-face {
  font-family: 'Nunito Sans';
  src: url("../fonts/NunitoSans-BlackItalic.eot");
  src: local("Nunito Sans Black Italic"), local("NunitoSans-BlackItalic"), url("../fonts/NunitoSans-BlackItalic.eot?#iefix") format("embedded-opentype"), url("../fonts/NunitoSans-BlackItalic.woff") format("woff"), url("../fonts/NunitoSans-BlackItalic.ttf") format("truetype");
  font-weight: 900;
  font-style: italic; }

@font-face {
  font-family: 'Nunito Sans';
  src: url("../fonts/NunitoSans-SemiBold.ttf.eot");
  src: local("Nunito Sans SemiBold"), local("NunitoSans-SemiBold"), url("../fonts/NunitoSans-SemiBold.ttf") format("truetype");
  font-weight: 600;
  font-style: normal; }

@font-face {
  font-family: 'Nunito Sans';
  src: url("../fonts/NunitoSans-Bold.ttf");
  src: local("Nunito Sans Bold"), local("NunitoSans-Bold"), url("../fonts/NunitoSans-Bold.ttf") format("truetype");
  font-weight: 700;
  font-style: normal; }

@font-face {
  font-family: 'Nunito Sans';
  src: url("../fonts/NunitoSans-ExtraBold.eot");
  src: local("Nunito Sans ExtraBold"), local("NunitoSans-ExtraBold"), url("../fonts/NunitoSans-ExtraBold.eot?#iefix") format("embedded-opentype"), url("../fonts/NunitoSans-ExtraBold.woff") format("woff"), url("../fonts/NunitoSans-ExtraBold.ttf") format("truetype");
  font-weight: 800;
  font-style: normal; }

@font-face {
  font-family: 'Nunito Sans';
  src: url("../fonts/NunitoSans-SemiBoldItalic.eot");
  src: local("Nunito Sans SemiBold Italic"), local("NunitoSans-SemiBoldItalic"), url("../fonts/NunitoSans-SemiBoldItalic.eot?#iefix") format("embedded-opentype"), url("../fonts/NunitoSans-SemiBoldItalic.woff") format("woff"), url("../fonts/NunitoSans-SemiBoldItalic.ttf") format("truetype");
  font-weight: 600;
  font-style: italic; }

@font-face {
  font-family: 'Nunito Sans';
  src: url("../fonts/NunitoSans-Black.eot");
  src: local("Nunito Sans Black"), local("NunitoSans-Black"), url("../fonts/NunitoSans-Black.eot?#iefix") format("embedded-opentype"), url("../fonts/NunitoSans-Black.woff") format("woff"), url("../fonts/NunitoSans-Black.ttf") format("truetype");
  font-weight: 900;
  font-style: normal; }

@font-face {
  font-family: 'Nunito Sans';
  src: url("../fonts/NunitoSans-ExtraLightItalic.eot");
  src: local("Nunito Sans ExtraLight Italic"), local("NunitoSans-ExtraLightItalic"), url("../fonts/NunitoSans-ExtraLightItalic.eot?#iefix") format("embedded-opentype"), url("../fonts/NunitoSans-ExtraLightItalic.woff") format("woff"), url("../fonts/NunitoSans-ExtraLightItalic.ttf") format("truetype");
  font-weight: 200;
  font-style: italic; }

@font-face {
  font-family: 'Nunito Sans';
  src: url("../fonts/NunitoSans-ExtraLight.eot");
  src: local("Nunito Sans ExtraLight"), local("NunitoSans-ExtraLight"), url("../fonts/NunitoSans-ExtraLight.eot?#iefix") format("embedded-opentype"), url("../fonts/NunitoSans-ExtraLight.woff") format("woff"), url("../fonts/NunitoSans-ExtraLight.ttf") format("truetype");
  font-weight: 200;
  font-style: normal; }

@font-face {
  font-family: 'Nunito Sans';
  src: url("../fonts/NunitoSans-BoldItalic.eot");
  src: local("Nunito Sans Bold Italic"), local("NunitoSans-BoldItalic"), url("../fonts/NunitoSans-BoldItalic.eot?#iefix") format("embedded-opentype"), url("../fonts/NunitoSans-BoldItalic.woff") format("woff"), url("../fonts/NunitoSans-BoldItalic.ttf") format("truetype");
  font-weight: 700;
  font-style: italic; }

@font-face {
  font-family: 'Nunito Sans';
  src: url("../fonts/NunitoSans-ExtraBoldItalic.eot");
  src: local("Nunito Sans ExtraBold Italic"), local("NunitoSans-ExtraBoldItalic"), url("../fonts/NunitoSans-ExtraBoldItalic.eot?#iefix") format("embedded-opentype"), url("../fonts/NunitoSans-ExtraBoldItalic.woff") format("woff"), url("../fonts/NunitoSans-ExtraBoldItalic.ttf") format("truetype");
  font-weight: 800;
  font-style: italic; }

@font-face {
  font-family: 'Nunito Sans';
  src: url("../fonts/NunitoSans-Italic.eot");
  src: local("Nunito Sans Italic"), local("NunitoSans-Italic"), url("../fonts/NunitoSans-Italic.eot?#iefix") format("embedded-opentype"), url("../fonts/NunitoSans-Italic.woff") format("woff"), url("../fonts/NunitoSans-Italic.ttf") format("truetype");
  font-weight: 400;
  font-style: italic; }

body {
  min-height: 100vh;
  margin: 0;
  color: #1A1A1A;
  font-family: 'Nunito Sans' !important;
  display: flex;
  flex-direction: column;
  position: relative; }
  body.opened {
    overflow: hidden; }

main {
  position: relative;
  flex: 1 0 auto; }

p, ol, ul, h1, h2, h3, button, form {
  margin: 0;
  padding: 0; }

em {
  font-style: normal;
  color: #F40000; }

button, input, textarea {
  font-family: inherit; }
  button:focus, input:focus, textarea:focus {
    outline: none; }

input {
  min-width: 0;
  font-size: inherit;
  appearance: none; }

ul, li {
  list-style-type: none; }

input::-webkit-input-placeholder {
  font-size: 15px;
  line-height: 18px;
  color: #999999; }

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button,
input[type=number] {
  -webkit-appearance: none;
  -moz-appearance: textfield; }

a {
  color: #1A1A1A;
  text-decoration: none;
  transition: color 0.3s; }
  a:hover {
    color: var(--main); }

svg {
  vertical-align: middle;
  transition: fill 0.3s; }

picture {
  display: block; }

img {
  display: block; }

source {
  display: none; }

*, ::before, ::after {
  box-sizing: border-box; }

:focus {
  outline: none; }

.content-seo {
  overflow: hidden;
  position: relative;
  max-width: 1920px;
  margin: 50px auto 0px auto; }
  .content-seo__wrapper {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-column-gap: 70px;
    height: 100%;
    height: 630px; }
    @media (max-width: 1199px) {
      .content-seo__wrapper {
        grid-column-gap: 30px; } }
    @media (max-width: 991px) {
      .content-seo__wrapper {
        grid-column-gap: 15px;
        height: 330px; } }
    @media (max-width: 767px) {
      .content-seo__wrapper {
        display: flex;
        flex-direction: column-reverse;
        height: unset; } }
  .content-seo__text {
    overflow: hidden;
    padding: 70px 0px;
    height: 100%; }
    @media (max-width: 991px) {
      .content-seo__text {
        padding: 30px 0; } }
    @media (max-width: 767px) {
      .content-seo__text {
        height: 290px;
        padding: 0 0 30px; } }
  .content-seo__image img {
    object-fit: cover;
    position: absolute;
    left: 50%;
    width: calc(50% - 30px); }
    @media (max-width: 991px) {
      .content-seo__image img {
        width: calc(50% - 15px); } }
    @media (max-width: 767px) {
      .content-seo__image img {
        width: 100%;
        position: static; } }

.sw-app {
  padding-bottom: 0 !important; }

.container {
  max-width: 1650px;
  margin: 0 auto;
  padding: 0 15px; }

.loading {
  opacity: .4;
  pointer-events: none;
  user-select: none; }

.show-after-load {
  opacity: 0 !important;
  visibility: hidden !important; }

.btn {
  cursor: pointer;
  border: none;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: transparent; }
  .btn--account {
    padding: 20px 20px; }
    .btn--account svg {
      width: 26px;
      height: 26px;
      fill: #1A1A1A;
      margin-right: 14px;
      transition: fill 0.3s; }
    .btn--account span {
      font-size: 18px;
      line-height: 26px;
      color: #1A1A1A;
      transition: color 0.3s; }
    .btn--account:hover svg {
      fill: #F40000; }
    .btn--account:hover span {
      color: #F40000; }
  .btn--filled {
    background-color: #C70000;
    transition: background-color 0.3s;
    border-radius: 3px; }
    .btn--filled span {
      font-weight: 700;
      font-size: 22px;
      line-height: 26px;
      color: #FCFCFC; }
      @media (max-width: 991px) {
        .btn--filled span {
          font-size: 16px;
          line-height: 22px; } }
    .btn--filled:hover {
      background-color: #F40000; }
  .btn--gray {
    background-color: transparent;
    transition: border-color 0.3s;
    border: 1px solid #BABABA;
    border-radius: 3px; }
    .btn--gray span {
      font-weight: 700;
      font-size: 22px;
      line-height: 26px;
      color: #BABABA;
      transition: color 0.3s; }
      @media (max-width: 991px) {
        .btn--gray span {
          font-size: 16px;
          line-height: 22px; } }
    .btn--gray:hover {
      border-color: #737373; }
      .btn--gray:hover span {
        color: #737373; }
  .btn--act.no-name {
    padding: 10px 12px; }
  .btn--act__icon {
    overflow: hidden;
    position: relative; }
    .btn--act__icon svg {
      width: 28px;
      height: 28px;
      transition: 0.3s; }
      .btn--act__icon svg:nth-child(1) {
        position: relative;
        fill: #1A1A1A; }
      .btn--act__icon svg:nth-child(2) {
        fill: #C70000;
        position: absolute;
        left: 0; }
      @media (max-width: 991px) {
        .btn--act__icon svg {
          width: 25px;
          height: 25px; } }
  .btn--act__name {
    margin-left: 14px; }
    .btn--act__name span, .btn--act__name h1 {
      font-size: 18px;
      line-height: 26px;
      color: #1A1A1A;
      transition: color 0.3s; }
    .btn--act__name h1 {
      color: #a22020; }
  .btn--act:hover .btn--act__icon svg {
    fill: #F40000; }
  .btn--act:hover .btn--act__name span {
    color: #F40000; }
  .btn--act[data-state='default'] .btn--act__icon svg:nth-child(1) {
    opacity: 1;
    top: 0; }
  .btn--act[data-state='default'] .btn--act__icon svg:nth-child(2) {
    opacity: 0;
    top: 25px; }
  .btn--act[data-state='added'] .btn--act__icon svg:nth-child(1) {
    opacity: 0;
    top: -25px; }
  .btn--act[data-state='added'] .btn--act__icon svg:nth-child(2) {
    opacity: 1;
    top: 0; }
  .btn--border {
    border: 1px solid #C70000;
    border-radius: 3px;
    transition: border-color 0.3s; }
    .btn--border span {
      font-weight: 700;
      font-size: 22px;
      line-height: 26px;
      color: #C70000;
      transition: color 0.3s; }
    .btn--border:hover {
      border-color: #F40000; }
      .btn--border:hover span {
        color: #F40000; }
  .btn--swal {
    padding: 15px 20px;
    margin: 0 5px; }

.d-flex-row {
  display: flex;
  flex-direction: row; }

.breadcrumbs {
  padding: 30px 0 60px; }
  @media (max-width: 991px) {
    .breadcrumbs {
      padding: 16px 0 20px; } }
  .breadcrumbs-item {
    font-size: 18px;
    line-height: 26px;
    color: #1A1A1A; }
    @media (max-width: 991px) {
      .breadcrumbs-item {
        font-size: 14px;
        line-height: 28px; } }
    .breadcrumbs-item--link {
      transition: color 0.3s; }
      .breadcrumbs-item--link:hover {
        color: #F40000; }
    .breadcrumbs-item--sep {
      color: #C70000;
      padding: 0 3px; }
  .breadcrumbs-back {
    display: block;
    padding: 30px 0 0; }

.page-title {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  margin: 0 0 30px; }
  @media (max-width: 991px) {
    .page-title {
      margin: 0 0 20px; } }
  .page-title__name {
    display: flex;
    flex-direction: column;
    align-items: flex-end; }
    @media (max-width: 991px) {
      .page-title__name {
        align-items: flex-start; } }
    .page-title__name span, .page-title__name h1 {
      display: block;
      font-weight: 600;
      font-size: 48px;
      line-height: 52px;
      text-transform: uppercase; }
      @media (max-width: 991px) {
        .page-title__name span, .page-title__name h1 {
          font-size: 24px;
          line-height: 28px; } }
    .page-title__name s {
      text-decoration: none;
      text-align: right;
      font-weight: 400;
      font-size: 18px;
      line-height: 28px;
      color: #919191;
      padding: 6px 0 0; }
  @media (max-width: 991px) {
    .page-title--desktop .page-title__next {
      display: none; } }
  .page-title--mobile {
    display: none;
    margin: 30px 0 0; }
    @media (max-width: 991px) {
      .page-title--mobile {
        display: flex;
        justify-content: center; } }

.page-section {
  padding: 0 0 100px; }
  @media (max-width: 991px) {
    .page-section {
      padding: 0 0 50px; } }

.action-link {
  display: flex;
  flex-direction: row;
  align-items: center; }
  .action-link--back svg {
    margin-right: 28px;
    margin-left: 0; }
    @media (max-width: 991px) {
      .action-link--back svg {
        margin-right: 12px; } }
  .action-link--forward {
    flex-direction: row-reverse; }
    .action-link--forward svg {
      transform: rotate(180deg);
      margin-left: 14px;
      margin-right: 0; }
      @media (max-width: 991px) {
        .action-link--forward svg {
          margin-left: 10px; } }
  .action-link svg {
    width: 20px;
    height: 20px;
    fill: #C70000;
    transition: fill 0.3s; }
    @media (max-width: 991px) {
      .action-link svg {
        width: 14px;
        height: 14px; } }
  .action-link span {
    font-weight: 600;
    font-size: 22px;
    line-height: 26px;
    letter-spacing: -0.5px;
    color: #C70000;
    transition: color 0.3s; }
    @media (max-width: 991px) {
      .action-link span {
        font-size: 16px;
        line-height: 20px; } }
  .action-link:hover svg {
    fill: #F40000; }
  .action-link:hover span {
    color: #F40000; }

.swiper {
  transition: opacity 0.3s;
  opacity: 1; }
  .swiper-init {
    opacity: 0; }
  .swiper-pagination {
    position: relative;
    z-index: 1; }
    .swiper-pagination-bullet {
      position: relative;
      z-index: 2;
      background-color: #BABABA;
      width: 12px;
      height: 12px;
      border-radius: 0px;
      margin: 0 15px;
      transition: 0.3s; }
      .swiper-pagination-bullet::before {
        content: '';
        display: block;
        position: absolute;
        z-index: 1;
        width: 23px;
        height: 23px;
        border: 1px solid #C70000;
        top: -4px;
        left: -4px;
        opacity: 0;
        transition: opacity 0.3s; }
      .swiper-pagination-bullet-active {
        width: 15px;
        height: 15px;
        background-color: #C70000; }
        .swiper-pagination-bullet-active::before {
          opacity: 1; }
  .swiper-navigation {
    cursor: pointer;
    border-top: 1px solid #737373;
    border-right: 1px solid #737373;
    transition: border-color 0.3s;
    width: 18px;
    height: 18px;
    position: relative;
    top: -2px; }
    .swiper-navigation:hover {
      border-color: #C70000; }
    .swiper-navigation__next {
      transform: rotate(45deg); }
      .swiper-navigation__next.wow {
        width: 30px;
        height: 30px; }
    .swiper-navigation__prev {
      transform: rotate(225deg); }

.swiper-button-disabled {
  opacity: .6;
  pointer-events: none; }

.no-photo {
  background-image: url("../images/placeholder.jpg");
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat; }

.simple-counter {
  cursor: pointer;
  user-select: none;
  display: grid;
  grid-template-columns: 28px 1fr 28px;
  background: #FFFFFF;
  align-items: center; }
  @media (max-width: 991px) {
    .simple-counter {
      grid-template-columns: 18px 1fr 18px; } }
  .simple-counter__minus svg, .simple-counter__plus svg {
    width: 28px;
    height: 28px;
    fill: #1A1A1A;
    transition: fill 0.3s; }
    @media (max-width: 991px) {
      .simple-counter__minus svg, .simple-counter__plus svg {
        width: 18px !important;
        height: 18px !important; } }
  .simple-counter__minus:hover svg, .simple-counter__plus:hover svg {
    fill: #F40000; }
  .simple-counter__field {
    border: none;
    text-align: center;
    font-weight: 700;
    font-size: 32px;
    line-height: 38px;
    color: #1A1A1A; }
    @media (max-width: 991px) {
      .simple-counter__field {
        font-size: 22px !important;
        line-height: 26px !important; } }

@media (max-width: 575px) {
  .swal2-title {
    padding: 10px 20px 0;
    font-size: 22px; } }

@media (max-width: 575px) {
  .swal2-html-container {
    font-size: 16px; } }

@media (max-width: 575px) {
  .swal2-actions {
    display: grid;
    grid-template-columns: 1fr;
    grid-row-gap: 10px;
    margin: 0;
    padding: 10px 15px 0; }
    .swal2-actions .btn--swal {
      width: 100%;
      margin: 0; } }

@media (max-width: 575px) {
  .swal2-icon {
    width: 50px;
    height: 50px; }
    .swal2-icon .swal2-icon-content {
      font-size: 30px; } }

.fancybox__thumbs .carousel__slide .fancybox__thumb::after {
  border-color: #C70000 !important; }

.theme-form {
  position: relative; }

.theme-loader {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #ffffffe6;
  z-index: 5; }
  .theme-loader img {
    width: 30%;
    height: 50%; }

.theme-input {
  display: block;
  position: relative;
  border: 1px solid #C9CED8;
  background-color: #fff;
  padding: 27px 24px 5px;
  width: 100%;
  transition-property: border-color;
  transition-duration: 0.3s;
  cursor: text; }
  @media (max-width: 991px) {
    .theme-input {
      padding: 20px 15px 2px; } }
  .theme-input:not(:last-child) {
    margin: 0 0 14px; }
  .theme-input.error {
    border-color: #C70000; }
    .theme-input.error .theme-input__placeholder {
      color: #C70000; }
  .theme-input__field {
    border: none;
    font-size: 22px;
    line-height: 26px;
    color: #1A1A1A;
    width: 100%;
    background-color: transparent;
    position: relative;
    z-index: 1; }
    .theme-input__field::placeholder {
      opacity: 0; }
    .theme-input__field:disabled {
      opacity: .6; }
    .theme-input__field:focus + span,
    .theme-input__field:not(:placeholder-shown) + span {
      top: 8px;
      font-size: 12px;
      line-height: 16px; }
      @media (max-width: 991px) {
        .theme-input__field:focus + span,
        .theme-input__field:not(:placeholder-shown) + span {
          top: 6px; } }
  .theme-input__placeholder {
    position: absolute;
    display: block;
    font-size: 22px;
    line-height: 26px;
    color: #737373;
    left: 24px;
    top: calc(50% - 12px);
    transition-property: font-size, top, color;
    transition-duration: 0.3s; }
    @media (max-width: 991px) {
      .theme-input__placeholder {
        font-size: 16px;
        line-height: 22px;
        top: calc(50% - 12px);
        left: 15px; } }
  .theme-input__password-toggle {
    width: 24px;
    height: 24px;
    position: absolute;
    right: 15px;
    top: calc(50% - 12px);
    fill: #999999;
    cursor: pointer;
    transition: fill 0.3s;
    z-index: 3; }
    .theme-input__password-toggle.active {
      fill: #F40000; }

.theme-textarea__field {
  resize: none;
  height: 150px; }

.theme-textarea__placeholder {
  top: 15px; }

.theme-toggle {
  user-select: none;
  cursor: pointer;
  display: grid;
  grid-template-columns: 65px 1fr;
  align-items: center; }
  .theme-toggle:not(:last-child) {
    margin: 0 0 10px; }
  .theme-toggle:hover .theme-toggle__name {
    color: #F40000; }
  .theme-toggle__input {
    display: none; }
    .theme-toggle__input:checked + div {
      background-color: #282828;
      border-color: #282828; }
      .theme-toggle__input:checked + div::before {
        right: 3px;
        border-color: #282828; }
  .theme-toggle__fake {
    position: relative;
    width: 50px;
    height: 28px;
    background-color: #E3E3E3;
    border: 1px solid #D0D0D0;
    transition: 0.3s; }
    .theme-toggle__fake::before {
      content: '';
      display: block;
      position: absolute;
      width: 50%;
      height: calc(100% - 4px);
      background-color: #FFFFFF;
      border: 1px solid #D0D0D0;
      top: 2px;
      right: calc(100% - 50% - 3px);
      transition: 0.3s; }
  .theme-toggle__name {
    font-size: 22px;
    line-height: 28px;
    color: #1A1A1A;
    transition: color 0.3s; }
    @media (max-width: 991px) {
      .theme-toggle__name {
        font-size: 14px;
        line-height: 18px; } }

.choice-block {
  cursor: pointer;
  user-select: none; }
  .choice-block__field {
    display: none; }
    .choice-block__field:checked + .choice-block__front {
      border-color: #D0D0D0;
      background-color: transparent; }
      .choice-block__field:checked + .choice-block__front .choice-block__fake {
        background-color: #282828;
        border-color: #282828; }
        .choice-block__field:checked + .choice-block__front .choice-block__fake svg {
          fill: #FFFFFF; }
  .choice-block__front {
    background-color: #F8F8F8;
    border: 1px solid transparent;
    padding: 20px 18px;
    transition: 0.3s;
    width: 100%;
    height: 100%; }
  .choice-block__fake {
    width: 22px;
    height: 22px;
    background-color: #FFFFFF;
    border: 1px solid #D0D0D0;
    border-radius: 50%;
    transition: background-color 0.3s, border-color 0.3s; }
    .choice-block__fake svg {
      width: 12px;
      height: 9px;
      fill: transparent;
      transition: fill 0.3s; }
  .choice-block__title {
    font-weight: 700;
    font-size: 15px;
    line-height: 20px;
    color: #333333;
    margin: 12px 0 0; }
  .choice-block__desc {
    font-size: 14px;
    line-height: 18px;
    color: #7D7D7D;
    margin: 8px 0 0; }
  .choice-block__link {
    padding: 10px 0 0;
    font-size: 14px;
    line-height: 18px;
    text-decoration: underline; }
    .choice-block__link:hover {
      color: #F40000; }

.theme-checkbox {
  cursor: pointer;
  display: grid;
  grid-template-columns: 38px 1fr;
  align-items: center;
  user-select: none; }
  @media (max-width: 991px) {
    .theme-checkbox {
      grid-template-columns: 30px 1fr; } }
  .theme-checkbox__field {
    display: none; }
    .theme-checkbox__field:checked + div::before {
      background-color: #C70000; }
  .theme-checkbox__fake {
    position: relative;
    width: 25px;
    height: 25px;
    background: #FFFFFF;
    border: 1px solid #DADADA;
    border-radius: 2px;
    transition: 0.3s; }
    @media (max-width: 991px) {
      .theme-checkbox__fake {
        width: 20px;
        height: 20px; } }
    .theme-checkbox__fake::before {
      content: '';
      display: block;
      width: 14px;
      height: 14px;
      background-color: transparent;
      border-radius: 3px;
      position: absolute;
      top: 0;
      bottom: 0;
      left: 0;
      right: 0;
      margin: auto; }
      @media (max-width: 991px) {
        .theme-checkbox__fake::before {
          width: 10px;
          height: 10px; } }
  .theme-checkbox__name {
    font-size: 22px;
    line-height: 26px;
    color: #1A1A1A;
    position: relative;
    top: 2px; }
    @media (max-width: 991px) {
      .theme-checkbox__name {
        font-size: 16px;
        line-height: 22px; } }
  .theme-checkbox__subname {
    display: block;
    padding: 12px 0 0;
    font-size: 18px;
    line-height: 24px;
    color: #919191; }
    @media (max-width: 991px) {
      .theme-checkbox__subname {
        padding: 5px 0 0;
        font-size: 12px;
        line-height: 18px; } }

.theme-submit {
  width: 100%;
  height: 60px; }
  @media (max-width: 991px) {
    .theme-submit {
      height: 50px; } }

.g-recaptcha {
  overflow: hidden; }
  .g-recaptcha:not(:last-child) {
    margin: 0 0 14px; }

.theme-policy {
  font-size: 18px;
  line-height: 24px;
  color: #1A1A1A;
  margin: 15px 0 0; }
  .theme-policy a {
    color: #F40000;
    text-decoration: underline; }
  @media (max-width: 991px) {
    .theme-policy {
      font-size: 14px;
      line-height: 18px; } }

.noUi-horizontal {
  height: 4px; }

.noUi-target {
  background: #999999;
  border: none;
  box-shadow: none;
  margin: 0 10px; }

.noUi-connect {
  background-color: #737373; }

.noUi-horizontal .noUi-handle {
  cursor: pointer;
  width: 15px;
  height: 15px;
  border: none;
  background: #737373;
  border-radius: 50%;
  box-shadow: none;
  right: -7px;
  top: -6px; }
  .noUi-horizontal .noUi-handle:after, .noUi-horizontal .noUi-handle:before {
    display: none; }

.noUi-touch-area {
  display: none; }

.radio-block {
  cursor: pointer; }
  .radio-block:not(:last-child) {
    margin-right: 10px; }
  .radio-block__field {
    display: none; }
    .radio-block__field:checked + span {
      border-color: #C70000; }
  .radio-block__name {
    display: block;
    font-weight: 400;
    font-size: 18px;
    line-height: 24px;
    background: #FFFFFF;
    border: 1px solid #DADADA;
    border-radius: 3px;
    padding: 10px 20px;
    transition: border-color 0.3s; }
    .radio-block__name:hover {
      border-color: #F40000; }

.theme-select {
  position: relative; }
  .theme-select__list {
    visibility: hidden;
    opacity: 0;
    position: absolute;
    top: calc(100% + 15px);
    left: -1px;
    min-width: calc(100% + 2px);
    border: 1px solid #c9ced8;
    background-color: #fff;
    border-radius: 0 0 5px 5px;
    box-shadow: 4px 6px 8px 0px rgba(35, 60, 80, 0.2);
    display: flex;
    flex-direction: column;
    padding: 5px 0;
    transition: 0.3s;
    z-index: 3; }
    .theme-select__list.opened {
      visibility: visible;
      opacity: 1;
      top: calc(100% + 5px); }
    .theme-select__list label {
      cursor: pointer;
      padding: 3px 10px;
      transition: background-color 0.3s; }
      .theme-select__list label:hover {
        background-color: #eaeaea; }
      .theme-select__list label input {
        display: none; }
      .theme-select__list label span {
        font-size: 16px;
        line-height: 24px;
        color: #282828;
        white-space: nowrap; }

.radio-image {
  display: block;
  cursor: pointer; }
  .radio-image:not(:last-child) {
    margin-right: 16px; }
    @media (max-width: 991px) {
      .radio-image:not(:last-child) {
        margin-right: 8px; } }
  .radio-image__container {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap; }
  .radio-image__field {
    display: none; }
    .radio-image__field:checked + div {
      outline-color: #C70000; }
  .radio-image__image {
    width: 85px;
    height: 60px;
    outline: 3px solid;
    outline-color: transparent;
    outline-offset: -3px;
    transition: outline-color 0.3s;
    border: 1px solid #DADADA; }
    @media (max-width: 991px) {
      .radio-image__image {
        width: 65px;
        height: 45px; } }
    .radio-image__image img {
      width: 100%;
      height: 100%;
      object-fit: cover; }

.file-upload {
  cursor: pointer; }
  .file-upload.active .file-upload__title {
    display: none; }
    .file-upload.active .file-upload__title--active {
      display: block; }
  .file-upload__field {
    display: none; }
  .file-upload__wrap {
    display: grid;
    grid-template-columns: 125px 1fr;
    grid-column-gap: 20px;
    align-items: center; }
    @media (max-width: 991px) {
      .file-upload__wrap {
        grid-template-columns: 70px 1fr;
        grid-column-gap: 15px; } }
  .file-upload__icon {
    border: 1px solid #DADADA;
    border-radius: 3px;
    height: 125px; }
    @media (max-width: 991px) {
      .file-upload__icon {
        height: 70px; } }
    .file-upload__icon svg {
      width: 54px;
      height: 70px; }
      @media (max-width: 991px) {
        .file-upload__icon svg {
          width: 30px;
          height: 40px; } }
  .file-upload__title span {
    font-size: 18px;
    line-height: 24px; }
    @media (max-width: 991px) {
      .file-upload__title span {
        font-size: 16px;
        line-height: 22px; } }
  .file-upload__title--active {
    display: none; }
    .file-upload__title--active button {
      margin: 5px 0 0; }
      .file-upload__title--active button span {
        font-size: 14px;
        color: #C70000;
        text-decoration: underline;
        transition: color 0.3s; }
      .file-upload__title--active button:hover span {
        color: #F40000; }

.theme-success {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center; }
  .theme-success svg {
    width: 36px;
    height: 28px;
    fill: #14A356;
    margin: 0 0 25px; }
  .theme-success span {
    font-weight: 700;
    font-size: 28px;
    line-height: 36px;
    color: #333333; }
    @media (max-width: 991px) {
      .theme-success span {
        font-size: 24px;
        line-height: 28px; } }

.modal__overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.6);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 9999;
  overflow-y: scroll; }

.modal__container {
  background-color: #fff;
  padding: 90px 105px;
  max-height: 95vh;
  overflow: auto;
  max-width: 760px; }
  @media (max-width: 991px) {
    .modal__container {
      max-width: 576px;
      padding: 25px; } }

.modal__content .theme-policy {
  text-align: center; }

.modal__title {
  font-weight: 700;
  font-size: 34px;
  line-height: 38px;
  color: #1A1A1A;
  margin: 0 0 30px; }
  .modal__title--center {
    text-align: center; }
  @media (max-width: 991px) {
    .modal__title {
      font-size: 28px;
      line-height: 28px; } }

.modal__subtitle {
  font-weight: normal;
  display: block;
  font-size: 22px;
  line-height: 26px;
  color: #1A1A1A;
  margin: 10px 0 0; }
  @media (max-width: 991px) {
    .modal__subtitle {
      font-size: 14px;
      line-height: 18px;
      margin: 12px 0 0; }
      .modal__subtitle br {
        display: none; } }

.modal__close {
  position: absolute;
  cursor: pointer;
  border: none;
  font-size: 38px;
  line-height: 38px;
  background-color: transparent;
  top: 0;
  right: 0;
  padding: 15px 30px;
  color: #1A1A1A;
  transition: color 0.3s; }
  @media (max-width: 991px) {
    .modal__close {
      font-size: 30px;
      line-height: 30px;
      padding: 8px 14px; } }
  .modal__close:hover {
    color: #F40000; }

@keyframes mmfadeIn {
  from {
    opacity: 0; }
  to {
    opacity: 1; } }

@keyframes mmfadeOut {
  from {
    opacity: 1; }
  to {
    opacity: 0; } }

@keyframes mmslideIn {
  from { }
  to { } }

@keyframes mmslideOut {
  from { }
  to { } }

.micromodal-slide {
  display: none; }

.micromodal-slide.is-open {
  display: block; }

.micromodal-slide[aria-hidden="false"] .modal__overlay {
  animation: mmfadeIn 0.3s cubic-bezier(0, 0, 0.2, 1); }

.micromodal-slide[aria-hidden="false"] .modal__container {
  animation: mmslideIn 0.3s cubic-bezier(0, 0, 0.2, 1); }

.micromodal-slide[aria-hidden="true"] .modal__overlay {
  animation: mmfadeOut 0.3s cubic-bezier(0, 0, 0.2, 1); }

.micromodal-slide[aria-hidden="true"] .modal__container {
  animation: mmslideOut 0.3s cubic-bezier(0, 0, 0.2, 1); }

.micromodal-slide .modal__container,
.micromodal-slide .modal__overlay {
  will-change: transform; }

.header {
  border-bottom: 1px solid #DADADA; }
  .header-container {
    display: grid;
    grid-template-columns: 145px 1fr; }
  .header-logo {
    display: block;
    padding: 15px 0; }
    .header-logo img {
      width: 95px;
      height: 88px;
      object-fit: contain; }
  .header-main {
    display: flex;
    flex-direction: column; }
  .header-top {
    justify-content: space-between;
    padding: 12px 0;
    border-bottom: 1px solid #DADADA; }
  .header-city {
    font-weight: 400;
    font-size: 18px;
    line-height: 26px;
    color: #737373; }
  .header-leed {
    display: flex;
    align-items: center;
    gap: 18px; }
    .header-leed-contacts {
      display: flex;
      align-items: center;
      gap: 8px;
      justify-content: center; }
    .header-leed .btn {
      color: #ffffff;
      padding: 5px 10px; }
    .header-leed a {
      font-size: 18px;
      line-height: 26px;
      color: #737373;
      transition: color 0.3s; }
      .header-leed a:hover {
        color: #F40000; }
  .header-bottom {
    justify-content: space-between;
    flex: 1; }
  .header-menu__list {
    display: flex;
    flex-direction: row;
    align-items: center;
    height: 100%; }
    @media (max-width: 1199px) {
      .header-menu__list {
        display: block; } }
  .header-menu__listitem {
    height: 100%;
    margin-right: 55px; }
    @media (max-width: 1199px) {
      .header-menu__listitem {
        margin-right: 0;
        margin: 0 0 15px;
        height: unset; } }
    .header-menu__listitem > a {
      white-space: nowrap;
      height: 100%; }
      @media (max-width: 1199px) {
        .header-menu__listitem > a {
          justify-content: flex-start; } }
      .header-menu__listitem > a:hover span {
        border-color: #F40000; }
      .header-menu__listitem > a span {
        font-size: 18px;
        line-height: 24px;
        color: #1A1A1A;
        border-bottom: 1px solid transparent;
        transition: border-color 0.3s; }
        @media (max-width: 1199px) {
          .header-menu__listitem > a span {
            font-size: 14px;
            line-height: 18px; } }
    @media (max-width: 1199px) {
      .header-menu__listitem.has-child.active > a::after {
        transform: rotate(180deg); } }
    .header-menu__listitem.has-child > a::after {
      content: '';
      width: 0;
      height: 0;
      border-style: solid;
      border-width: 6px 6px 0 6px;
      border-color: #1A1A1A transparent transparent transparent;
      position: relative;
      margin-left: 14px; }
      @media (max-width: 1199px) {
        .header-menu__listitem.has-child > a::after {
          border-width: 4px 4px 0 4px;
          margin-left: 10px; } }
    .header-menu__listitem.has-child:hover .header-menu__drop {
      opacity: 1;
      visibility: visible;
      transform: translate(0px, 0px); }
  .header-menu__drop {
    position: absolute;
    background: #ffffff;
    border: 1px solid #DADADA;
    padding: 25px 0;
    z-index: 99;
    opacity: 0;
    visibility: hidden;
    transform: translate(0px, -8px); }
    @media (max-width: 1199px) {
      .header-menu__drop {
        position: static;
        visibility: visible;
        opacity: 1;
        padding: 0;
        border: none;
        padding-left: 20px;
        display: none;
        transform: none !important; }
        .header-menu__drop.active {
          display: block; } }
  .header-menu__dropitem > a {
    display: block;
    padding: 10px 35px;
    font-size: 18px;
    line-height: 24px; }
    .header-menu__dropitem > a:hover {
      color: #F40000; }
    @media (max-width: 1199px) {
      .header-menu__dropitem > a {
        font-size: 14px;
        line-height: 18px;
        padding: 5px 0; } }
  .header-links {
    align-items: center; }
    .header-links .btn:nth-last-child(-n+2) {
      padding-right: 0; }
    .header-links__count {
      width: 30px;
      height: 30px;
      background-color: #C70000;
      border-radius: 50%;
      font-size: 14px;
      line-height: 18px;
      color: #FCFCFC;
      transition: 0.3s; }
      .header-links__count:not(:last-child) {
        margin-right: 20px; }
      .header-links__count:last-child {
        margin-left: 20px; }
      .header-links__count:empty {
        width: 0;
        height: 0;
        margin: 0; }

.header-mobile {
  padding: 0 15px 0 15px;
  display: grid;
  grid-template-columns: 40px 1fr 40px;
  align-items: center; }
  .header-mobile-logo {
    display: block; }
    .header-mobile-logo img {
      width: 40px;
      height: 40px;
      object-fit: contain; }
  .header-mobile-logo-horizontal {
    display: block;
    padding-top: 10px;
    padding-bottom: 10px; }
    .header-mobile-logo-horizontal img {
      max-height: 40px;
      object-fit: contain; }

.header-search {
  padding-left: 15px;
  display: grid;
  grid-template-columns: 1fr 30px;
  background-color: #EBEBEB;
  border-radius: 2px; }
  .header-search input {
    border: none;
    background-color: transparent;
    font-size: 12px;
    line-height: 18px;
    color: #737373;
    width: 100%;
    height: 30px; }
    .header-search input::placeholder {
      font-size: 12px;
      line-height: 18px;
      color: #737373; }
  .header-search button {
    width: 30px;
    height: 30px; }
    .header-search button svg {
      width: 12px;
      height: 12px;
      stroke: #BABABA;
      fill: transparent; }

.header-contacts {
  display: flex;
  height: 100%;
  justify-content: flex-end;
  padding: 0 10px;
  gap: 10px; }

.header-phone {
  height: 100%;
  background-color: #1ECF3E;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  font-size: 10px;
  color: #ffffff;
  padding: 0 11px; }

.header-social {
  padding-top: 10px;
  padding-bottom: 10px;
  display: flex;
  justify-content: center;
  align-items: center; }
  .header-social.telegram {
    fill: #2ca5e0; }
  .header-social.whatsapp {
    fill: #31db51; }

.header-burger {
  padding: 20px 10px 20px 10px; }
  .header-burger__wrap {
    position: relative;
    width: 20px;
    height: 16px; }
  .header-burger span {
    display: block;
    position: absolute;
    width: 100%;
    height: 2px;
    background-color: #c60000;
    transition: 0.3s;
    transition-property: top, bottom, opacity, transform; }
    .header-burger span:nth-child(1) {
      top: 0; }
    .header-burger span:nth-child(2) {
      top: 0;
      bottom: 0;
      margin: auto; }
    .header-burger span:nth-child(3) {
      bottom: 0; }
  .header-burger.opened span:nth-child(1) {
    top: calc(50% - 1px);
    transform: rotate(45deg); }
  .header-burger.opened span:nth-child(2) {
    opacity: 0; }
  .header-burger.opened span:nth-child(3) {
    bottom: calc(50% - 1px);
    transform: rotate(-45deg); }

.mobile-menu {
  position: fixed;
  z-index: 999;
  top: 60px;
  bottom: 0;
  left: 100%;
  width: 70%;
  background-color: #fff;
  overflow: scroll;
  transition: cubic-bezier(0.075, 0.82, 0.165, 1) 0.3s; }
  .mobile-menu::after {
    content: '';
    display: block;
    position: fixed;
    top: 60px;
    left: -100%;
    background-color: #000000;
    opacity: 0.5;
    width: 30%;
    height: 100%;
    transition: 0.3s; }
  .mobile-menu.opened {
    left: 30%; }
    .mobile-menu.opened::after {
      left: 0%; }
  .mobile-menu__wrap {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    padding: 30px;
    height: 100%; }
  .mobile-menu__contacts {
    margin: 30px 0 0; }
    .mobile-menu__contacts a {
      display: block;
      font-size: 12px;
      line-height: 16px;
      text-transform: uppercase;
      color: #1A1A1A;
      margin: 0 0 18px; }
      .mobile-menu__contacts a span {
        border-bottom: 1px solid #F40000; }
  .mobile-menu__location {
    font-size: 12px;
    line-height: 16px;
    color: #737373; }

.mobile-navigation {
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
  background-color: #FCFCFC;
  box-shadow: 0px 0px 8px rgba(0, 0, 0, 0.05);
  z-index: 99; }
  .mobile-navigation__wrap {
    display: grid;
    grid-template-columns: repeat(5, 1fr); }
  .mobile-navigation__item {
    flex-direction: column;
    padding: 8px 10px; }
    .mobile-navigation__item svg {
      width: 20px;
      height: 20px;
      margin: 0 0 5px; }
    .mobile-navigation__item span {
      font-weight: 400;
      font-size: 10px;
      line-height: 14px;
      text-align: center;
      color: #1A1A1A; }

.leadback {
  padding: 105px 0; }
  @media (max-width: 991px) {
    .leadback {
      padding: 50px 0; } }
  .leadback-form {
    margin: 40px auto 16px;
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-column-gap: 20px;
    max-width: 540px; }
    .leadback-form--long {
      max-width: 790px; }
    .leadback-form .theme-input {
      margin: 0; }
    @media (max-width: 767px) {
      .leadback-form {
        grid-template-columns: 1fr;
        grid-template-rows: 1fr 50px;
        grid-column-gap: 0px;
        grid-row-gap: 10px;
        margin: 28px auto 10px; } }
  .leadback-content {
    text-align: center; }
    .leadback-content__title {
      font-weight: 600;
      font-size: 75px;
      text-transform: uppercase;
      color: #1A1A1A;
      padding: 0 0 12px; }
      @media (max-width: 991px) {
        .leadback-content__title {
          font-size: 30px;
          line-height: 38px;
          padding: 0 0 10px; } }
    .leadback-content__text {
      font-weight: 400;
      font-size: 22px;
      color: #1A1A1A; }
      @media (max-width: 991px) {
        .leadback-content__text {
          font-size: 14px;
          line-height: 18px; } }
    .leadback-content__link {
      font-weight: 400;
      font-size: 18px;
      line-height: 22px;
      text-align: center;
      color: #1A1A1A; }
      @media (max-width: 991px) {
        .leadback-content__link {
          font-size: 12px;
          line-height: 16px; } }
      .leadback-content__link a {
        color: #C70000;
        border-bottom: 1px solid #C70000;
        transition: border-color 0.3s, color 0.3s; }
        .leadback-content__link a:hover {
          border-color: #F40000;
          color: #F40000; }

.footer {
  background-color: #1A1A1A; }
  .footer-main__container {
    padding: 65px 0;
    display: grid;
    grid-template-columns: 280px 1fr 370px; }
  .footer-logo__img {
    margin: 0 0 25px; }
    .footer-logo__img img {
      width: 140px;
      height: 130px;
      object-fit: contain; }
  .footer-logo__desc span {
    font-weight: 400;
    font-size: 18px;
    line-height: 22px;
    color: #737373; }
  .footer-menu {
    grid-column-gap: 60px; }
    @media (max-width: 1199px) {
      .footer-menu {
        display: grid;
        grid-template-columns: repeat(3, 1fr); } }
    @media (max-width: 575px) {
      .footer-menu {
        grid-template-columns: repeat(2, 1fr); } }
    @media (max-width: 419px) {
      .footer-menu {
        grid-template-columns: repeat(1, 1fr); } }
    @media (max-width: 991px) {
      .footer-menu__group:not(:last-child) {
        margin: 0 0 25px; } }
    .footer-menu__head {
      margin: 0 0 12px; }
      .footer-menu__head a {
        font-size: 22px;
        line-height: 28px;
        color: #737373;
        padding: 8px 0;
        transition: color 0.3s; }
        @media (max-width: 991px) {
          .footer-menu__head a {
            font-size: 14px;
            line-height: 18px;
            padding: 5px 0; } }
        .footer-menu__head a:hover {
          color: #F40000; }
    .footer-menu__nav a {
      display: block;
      font-size: 22px;
      line-height: 28px;
      color: #FCFCFC;
      padding: 8px 0;
      transition: color 0.3s; }
      @media (max-width: 991px) {
        .footer-menu__nav a {
          font-size: 14px;
          line-height: 18px;
          padding: 5px 0; } }
      .footer-menu__nav a:hover {
        color: #F40000; }
  .footer-search {
    position: relative;
    background-color: #303030;
    border-radius: 3px;
    padding-left: 25px;
    margin: 0 0 20px; }
    .footer-search input {
      border: none;
      background-color: transparent;
      font-size: 22px;
      line-height: 28px;
      color: #737373;
      height: 60px;
      width: 100%; }
      @media (max-width: 991px) {
        .footer-search input {
          font-size: 14px;
          line-height: 18px;
          height: 30px; } }
      .footer-search input::placeholder {
        font-size: 22px;
        line-height: 28px;
        color: #737373; }
        @media (max-width: 991px) {
          .footer-search input::placeholder {
            font-size: 14px;
            line-height: 18px; } }
    .footer-search button {
      position: absolute;
      width: 25px;
      height: 25px;
      right: 16px;
      top: calc(50% - 13px);
      background-color: transparent; }
      .footer-search button svg {
        fill: transparent;
        stroke: #737373;
        width: 25px;
        height: 25px; }
        @media (max-width: 991px) {
          .footer-search button svg {
            width: 12px;
            height: 12px; } }
  .footer-contacts__item > a {
    display: inline-block;
    padding: 8px 0;
    font-size: 22px;
    line-height: 28px;
    color: #FCFCFC;
    transition: color 0.3s; }
    @media (max-width: 991px) {
      .footer-contacts__item > a {
        padding: 5px 0;
        font-size: 14px;
        line-height: 18px; } }
    .footer-contacts__item > a:hover {
      color: #F40000; }
  .footer-contacts__row {
    display: flex;
    align-items: center;
    justify-content: space-between; }
  .footer-contacts__social {
    display: grid;
    grid-template-columns: repeat(3, 40px);
    grid-column-gap: 18px; }
    @media (max-width: 1199px) {
      .footer-contacts__social {
        margin: 30px 0; } }
    .footer-contacts__social > a svg {
      width: 40px;
      height: 40px;
      fill: #FCFCFC;
      transition: fill 0.3s; }
      @media (max-width: 991px) {
        .footer-contacts__social > a svg {
          width: 30px;
          height: 30px; } }
    .footer-contacts__social > a:hover svg {
      fill: #F40000; }
  .footer-bottom {
    padding: 18px 0;
    border-top: 1.5px solid #303030; }
    .footer-bottom__container {
      justify-content: space-between; }
      @media (max-width: 991px) {
        .footer-bottom__container {
          align-items: center;
          flex-direction: column; } }
  .footer-company {
    font-weight: 400;
    font-size: 18px;
    line-height: 28px;
    color: #737373; }
    @media (max-width: 991px) {
      .footer-company {
        font-size: 12px;
        line-height: 16px; } }
    .footer-company--link {
      transition: color 0.3s; }
      @media (max-width: 991px) {
        .footer-company--link {
          text-decoration: underline;
          margin: 12px 0 18px; } }
      .footer-company--link:hover {
        color: #F40000; }
  .footer-ps svg {
    width: 150px;
    height: 30px;
    fill: #737373;
    transition: fill 0.3s; }
    @media (max-width: 991px) {
      .footer-ps svg {
        width: 85px;
        height: 18px; } }
  .footer-ps span {
    font-weight: 400;
    font-size: 18px;
    line-height: 28px;
    color: #737373;
    transition: color 0.3s; }
    @media (max-width: 991px) {
      .footer-ps span {
        font-size: 12px;
        line-height: 16px; } }
  .footer-ps:hover svg {
    fill: #F40000; }
  .footer-ps:hover span {
    color: #F40000; }

.footer-mobile__container {
  padding: 25px 0; }

.search-line {
  display: flex;
  align-items: center;
  background: #FFFFFF;
  border: 1px solid #C70000;
  width: 100%;
  height: 45px;
  position: relative; }
  .search-line.error {
    border-color: #ff2828; }
  .search-line input {
    flex: 1 0 auto;
    border: none;
    height: 100%;
    padding: 0 15px; }
  .search-line button {
    border: none;
    width: 45px;
    height: 45px;
    position: relative;
    right: -1px; }
    .search-line button svg {
      width: 20px;
      height: 20px;
      stroke: #737373;
      fill: transparent; }
  .search-line__result {
    display: none;
    width: 100%;
    position: absolute;
    top: calc(100% + 1px);
    background-color: #fff;
    padding: 30px 20px; }
    .search-line__result.opened {
      display: block; }
    .search-line__result:empty {
      display: none !important; }
  .search-line__title {
    font-weight: 500;
    font-size: 15px;
    line-height: 18px;
    color: #282828;
    margin: 0 0 10px; }
  .search-line__item {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding: 5px 0; }
    .search-line__item span {
      font-size: 15px;
      line-height: 18px;
      color: #282828;
      transition: color 0.3s; }
    .search-line__item svg {
      height: 12px;
      width: 8px;
      fill: #999999; }
    .search-line__item:hover span {
      color: #F40000; }
  .search-line__extra {
    border-top: 1px solid #D0D0D0;
    padding: 15px 0 0;
    margin: 30px 0 0; }

.footer-form {
  position: relative;
  background-image: url(/static/images/brick-background.png);
  background-size: cover;
  margin-top: clamp(30px, 10vw, 200px);
  padding: 50px 0; }
  @media (max-width: 767px) {
    .footer-form {
      margin-top: clamp(30px, 30vw, 200px); } }
  .footer-form__inner {
    display: grid;
    grid-template-columns: 1fr 1fr;
    justify-content: center;
    align-items: center;
    grid-column-gap: 5%; }
    @media (max-width: 767px) {
      .footer-form__inner {
        grid-template-columns: 1fr; } }
  .footer-form__part {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-end; }
    @media (max-width: 767px) {
      .footer-form__part {
        align-items: center; } }
  .footer-form__image {
    height: 115%;
    margin-top: -20%; }
    .footer-form__image img {
      width: 100%; }
    @media (max-width: 767px) {
      .footer-form__image {
        padding: 0 3%; } }
    @media (max-width: 419px) {
      .footer-form__image {
        margin-top: -25%; } }
  .footer-form__title {
    font-size: 34px;
    line-height: 125%;
    font-weight: 600;
    width: 65%;
    margin-bottom: 20px;
    text-transform: uppercase; }
    @media (max-width: 767px) {
      .footer-form__title {
        width: 95%;
        font-size: 24px;
        margin-top: 50px;
        text-align: center; } }
    @media (max-width: 419px) {
      .footer-form__title {
        margin-top: 20px; } }
  .footer-form__subtitle {
    font-size: 21px;
    line-height: 125%;
    width: 65%;
    margin-bottom: 25px; }
    @media (max-width: 767px) {
      .footer-form__subtitle {
        text-align: center; } }
    @media (max-width: 419px) {
      .footer-form__subtitle {
        font-size: 12px;
        width: 95%; } }

.not-found__container {
  position: relative; }

.not-found__404 {
  text-align: center; }
  @media (max-width: 991px) {
    .not-found__404 {
      position: absolute;
      top: 0;
      width: 100%;
      height: 100%; } }
  .not-found__404 svg {
    fill: #DADADA;
    opacity: 0.3;
    width: 100%;
    height: 600px; }
    @media (max-width: 991px) {
      .not-found__404 svg {
        height: unset; } }

.not-found__content {
  position: absolute;
  top: 0;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 50px 0; }
  @media (max-width: 991px) {
    .not-found__content {
      padding: 0;
      position: relative; } }
  @media (max-width: 991px) {
    .not-found__content-text {
      padding: 0 0 30px; } }
  .not-found__content-text span {
    display: block;
    max-width: 530px;
    font-size: 22px;
    line-height: 26px; }
    @media (max-width: 991px) {
      .not-found__content-text span {
        font-size: 16px;
        line-height: 22px; } }
